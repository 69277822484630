import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Privacy = () => {
   const today = new Date();
   const formattedDate = today.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
   

  return (
    <Container className="mt-4 mb-4">
        <Row>
            <Col>  
            <div className="privacy-policy">
            <h1>Nomad Privacy Policies</h1>
            <p><strong>Effective Date: {formattedDate}</strong></p>

            <h2>1. Introduction</h2>
            <p>Welcome to Nomad, a unique voice-to-voice communication experience. At Nomad, we value the privacy and security of our users and are committed to protecting your personal information. This policy explains in detail how we handle and protect your data.</p>

            <h2>2. Collection and Use of Personal Information</h2>
            <p><strong>2.1 Information We Collect:</strong> When registering for Nomad, we ask for your first name, last name, and email address. This information is essential for creating and managing your account.</p>
            <p><strong>2.2 Use of Your Information:</strong> Your first name is used to identify you within the application, thereby enhancing your user experience. Your last name and email address are used exclusively for internal purposes, such as account administration and direct communication with you.</p>

            <h2>3. Security and Data Protection</h2>
            <p><strong>3.1 Security Measures:</strong> We implement state-of-the-art security measures, including the use of databases provided by MongoDB and Firebase, known for their robust security protocols, to ensure the protection of your personal information against unauthorized access, alteration, disclosure, or destruction.</p>
            <p><strong>3.2 Commitment to Security:</strong> We continually assess and update our security practices to address new challenges and threats to data security.</p>

            <h2>4. Consent and User Control</h2>
            <p><strong>4.1 Obtaining Consent:</strong> Your consent to the use of your personal information is explicitly obtained at the time of your account creation on Nomad.</p>
            <p><strong>4.2 User Rights:</strong> Although we currently do not offer a direct function to edit your information, we are dedicated to providing this capability in the near future. In the meantime, any requests for access, correction, or deletion of your personal information can be directed to our support team.</p>

            <h2>5. Absence of Cookies and Tracking</h2>
            <p><strong>5.1 Use of Cookies:</strong> Nomad operates without the use of cookies or tracking technologies, reaffirming our commitment to user privacy.</p>

            <h2>6. Modifications to the Privacy Policy</h2>
            <p><strong>6.1 Updates and Notifications:</strong> We reserve the right to modify this privacy policy. Significant changes will be communicated via registered email, ensuring you are always informed about how we protect your information.</p>

            <h2>7. Contact</h2>
            <p><strong>7.1 Communication Channels:</strong> If you have questions, comments, or concerns about our privacy practices, do not hesitate to contact us at martin@moreirawebdesign.com. We are here to help.</p>

            <h2>8. Subscription Details</h2>
            <p><strong>8.1 Variety of Options:</strong> We offer Bronze, Silver, and Gold subscriptions, differentiated only by their prices and not by the handling of personal data. Our commitment to privacy and security is uniform across all subscription levels.</p>
            </div>
            </Col>
        </Row>
    </Container>
  );
}

export default Privacy;

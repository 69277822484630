import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Header() {
  const handleRedirect = () => {
    window.location.href = 'https://app.nomad.uy/';
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand as={Link} to="/">
          <img
            src={`${process.env.PUBLIC_URL}/images/nomad_logo.svg`}
            width="180"
            height="auto"
            className="d-inline-block align-top"
            alt="Nomad logo"
          />
          {' '}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <Nav.Link as={Link} to="/suscriptions">Suscriptions</Nav.Link>
            <Nav.Link as={Link} to="/privacy">Privacy Policy</Nav.Link>
            <Nav.Link as={Link} to="/faq">Faq</Nav.Link>
            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
            <Nav.Link as="button" className="btn btn-primary ms-3" onClick={handleRedirect}>Login</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;

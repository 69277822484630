import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

function Home() {
  const handleRedirect = () => {
    window.location.href = 'https://app.nomad.uy/';
  };

  return (
    <Container className="mt-4">
      <Row className="mb-4">
        <Col>
          <h1>Welcome to Nomad</h1>
          <p>
            In a world where communication is essential, Nomad is your gateway to seamless interaction. Our advanced application utilizes natural language processing, speech recognition, and computer vision technologies to offer real-time translations, revolutionizing how you connect with the world.
          </p>
          <Button variant="primary" onClick={handleRedirect}>Start Now For free!</Button>
        </Col>
      </Row>
      
      <Row className="mb-4">
        <Col>
          <h2>The Technology Behind Nomad</h2>
          <p>
            Nomad employs state-of-the-art algorithms in natural language processing and speech recognition to capture and translate speech instantly. Our advanced computer vision technology enables precise and rapid translation of text from images, ensuring efficient and uninterrupted communication.
          </p>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h2>Key Features</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Auto Nomad</Card.Title>
              <Card.Text>
                Real-time audio-to-audio translation, ideal for multilingual conversations without barriers. Our speech recognition and speech synthesis technology ensure a smooth and accurate experience.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Vision Nomad</Card.Title>
              <Card.Text>
                Translation of text from captured images, providing instant and precise results thanks to our advanced computer vision technology.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Manual Nomad</Card.Title>
              <Card.Text>
                Record audios, translate them, and transcribe them. Perfect for taking notes or recording conversations in different languages, using our voice-to-text transcription and translation capabilities.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h2>Tailored Subscriptions</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Explorer</Card.Title>
              <Card.Text>
                Free and designed for the curious, offering limited functionalities for an initial experience with our tools.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Pioneer</Card.Title>
              <Card.Text>
                For USD 20 per month, get access to 50 translation points, the most demanded languages, and text-to-speech and speech-to-speech capabilities, with reliable technical support.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Nomad</Card.Title>
              <Card.Text>
                For USD 40 per month, enjoy 100 translation points, access to all available languages, high-quality voice translations, and exclusive features for global travelers, with premium technical support.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          <h2>How It Works</h2>
          <p>
            Nomad is as simple as it is effective. Our artificial intelligence technology recognizes and translates spoken language in real-time, reproducing the translation in the selected language. Each translation consumes points based on the length of the audio and the translated text. You can save your translated audios and check your remaining points from the user panel.
          </p>
        </Col>
      </Row>
      
      <Row className="mb-4">
        <Col>
          <h2>Join the Communication Revolution</h2>
          <p>
            With Nomad, the language barrier disappears. Join us and transform the way you communicate with the world. Nomad's advanced technology and intuitive design allow you to explore new cultures and languages with the ease of a click.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;

import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';  

const ContactForm = () => {
    const [isSubmitting, setIsSubmitting] = useState(false); 

    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true); 
        
        const formData = {
            firstName: event.target.firstName.value,
            lastName: event.target.lastName.value,
            email: event.target.email.value,
            subject: event.target.subject.value,
            comment: event.target.comment.value,
        };

        const apiUrl = process.env.REACT_APP_API_URL || "https://nomad.uy/api";
        axios.post(`${apiUrl}/upload`, formData)
            .then(response => {
                Swal.fire({
                    icon: 'success',
                    title: 'Formulario enviado con éxito',
                    showConfirmButton: false,
                    timer: 1500
                });

                event.target.reset();
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Error enviando el formulario',
                    text: error.message,
                });
                console.error('Error enviando el formulario:', error);
            })
            .finally(() => {
                setIsSubmitting(false); 
            });
    };

    return (
        <div className="contact-container container mt-5">
            <div className="contact-text">
                <h2>Get in Touch</h2>
                <p>
                    We’d love to hear from you! Whether you’re curious about our services, 
                    have a question, or need support, feel free to reach out to us. 
                    Just fill in the form on the right, and we'll get back to you as soon as possible.
                </p>
            </div>
            <form id="contactForm" onSubmit={handleSubmit} className="contact-form">
                <div className="form-row">
                    <div className="form-group col-md-6">
                        <label htmlFor="firstName">First Name</label>
                        <input type="text" className="form-control" id="firstName" name="firstName" required />
                    </div>
                    <div className="form-group col-md-6">
                        <label htmlFor="lastName">Last Name</label>
                        <input type="text" className="form-control" id="lastName" name="lastName" required />
                    </div>
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" className="form-control" id="email" name="email" required />
                </div>
                <div className="form-group">
                    <label htmlFor="subject">Subject</label>
                    <select className="form-control" id="subject" name="subject" required>
                        <option value="">Select an option</option>
                        <option value="sales">Sales</option>
                        <option value="support">Support</option>
                    </select>
                </div>
                <div className="form-group">
                    <label htmlFor="comment">Comment</label>
                    <textarea className="form-control" id="comment" name="comment" rows="3" required></textarea>
                </div>
                <button type="submit" className="btn btn-primary" disabled={isSubmitting}>Submit</button>
            </form>
        </div>
    );
};

export default ContactForm;
